'use client';

import { MAX_SPOT_MARKETS_PER_USER } from 'src/constants/markets';
import useAccountData from './useAccountData';
import { matchEnum } from '@drift/common';
import { MarketType } from '@drift-labs/sdk';

const DEFAULT_ARRAY: number[] = [];

export const useExceedsMaxSpotBalance = (spotMarketIndexToAdd: number) => {
	const currentAccount = useAccountData();
	const spotBalancesMarketIndices =
		currentAccount?.spotBalances.map((balance) => balance.asset.marketIndex) ??
		DEFAULT_ARRAY;
	const openSpotOrdersMarketIndices =
		currentAccount?.openOrders
			.filter((order) => matchEnum(order.marketIndex, MarketType.SPOT))
			.map((order) => order.marketIndex) ?? DEFAULT_ARRAY;

	const futureSpotMarketIndices = Array.from(
		new Set([
			...spotBalancesMarketIndices,
			...openSpotOrdersMarketIndices,
			spotMarketIndexToAdd,
		])
	);

	return futureSpotMarketIndices.length > MAX_SPOT_MARKETS_PER_USER;
};
