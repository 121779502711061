'use client';

import React from 'react';
import { twMerge } from 'tailwind-merge';
import SemanticChip from '../Utils/SemanticChip';
import GradientText from '../Utils/GradientText';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { IconProps } from '@drift-labs/icons';
import { JSX } from 'react';

export type TabbedlNavigationProps = {
	onChange: (selection: string) => void;
	currentSelection: string;
	options: {
		value: string;
		label: string | React.ReactNode;
		icon?: (allProps: IconProps) => JSX.Element;
		numberIndicator?: number;
		alert?: boolean;
		onClick?: () => void;
	}[];
	opts?: {
		inline?: boolean; // Use this if we need to style the tabs as if they're rendered as inline content.
		xlPadding?: boolean; // Increases the padding in the button options
		growTabs?: boolean; // Makes the tabs grow to fill available space
		fullWidth?: boolean;
		noBorder?: boolean;
		className?: string;
		rounded?: boolean;
		noDividers?: boolean;
		iconsOnRight?: boolean; // Display icons on the right side of the label
	};
};

const NavigationTab = (props: {
	onClick: () => void;
	label: string | React.ReactNode;
	selected: boolean;
	numberIndicator?: number;
	alert?: boolean;
	opts?: TabbedlNavigationProps['opts'];
	icon?: (allProps: IconProps) => JSX.Element;
}) => {
	const isMobile = useIsMobileScreenSize();
	const Icon = props.icon;

	return (
		<div
			className={twMerge(
				`py-1 px-4 sm:px-3 flex items-center relative`,
				`hover:cursor-pointer hover:bg-container-bg-hover`,
				'text-text-label',
				'border-r',
				isMobile && 'last:border-none',
				'border-container-border',
				'leading-3',
				'h-[34px]',
				props.selected ? 'bg-container-bg-hover' : '',
				props?.opts?.xlPadding ? 'py-2 px-4' : '',
				props?.opts?.growTabs ? 'flex-grow basis-0 justify-center' : '',
				props?.opts?.rounded ? 'rounded-sm' : '',
				props?.opts?.noDividers ? 'border-none' : ''
			)}
			onClick={props.onClick}
		>
			<div className="flex items-center w-full">
				<div className="flex items-center gap-1 mx-auto">
					{Icon && !props.opts?.iconsOnRight ? (
						<Icon color={props.selected ? 'url(#icon-gradient)' : undefined} />
					) : null}

					{typeof props.label === 'string' ? (
						<GradientText disabled={!props.selected}>
							<span className="font-normal">{props.label}</span>
						</GradientText>
					) : (
						props.label
					)}

					{Icon && props.opts?.iconsOnRight ? (
						<Icon color={props.selected ? 'url(#icon-gradient)' : undefined} />
					) : null}
				</div>
				{props.numberIndicator ? (
					<span
						className="inline-block px-1 ml-2 font-numeral text-chip-default-text bg-chip-default-bg monospace-off"
						style={{
							fontSize: '10px',
							borderRadius: '3px',
						}}
					>
						<span className="mt-1">{props.numberIndicator}</span>
					</span>
				) : props.alert ? (
					<SemanticChip className="ml-2 mb-0.5" status="red" />
				) : undefined}
			</div>
		</div>
	);
};

const HighlightedTabs = (props: TabbedlNavigationProps) => {
	const onSelect = (selection: string) => {
		props.onChange(selection);
	};

	return (
		<div
			className={twMerge(
				`flex border-b bg-container-bg border-container-border whitespace-nowrap`,
				props?.opts?.inline ? 'border-t border-l' : '',
				props?.opts?.fullWidth ? 'w-full' : '',
				props?.opts?.noBorder ? 'border-none' : '',
				props?.opts?.className
			)}
		>
			{props.options.map((opt) => (
				<NavigationTab
					key={opt.value}
					onClick={() => {
						onSelect(opt.value);
					}}
					label={opt.label}
					selected={props.currentSelection === opt.value}
					numberIndicator={opt.numberIndicator}
					opts={props.opts}
					icon={opt.icon}
				/>
			))}
		</div>
	);
};

export default HighlightedTabs;
