import { useRef } from 'react';

/**
 * Store a lookup of page numbers to known page tokens.
 * Page tokens are identifiers used by the API for pagination, to keep track of where to continue fetching the next set of records.
 */
export const useTrackNextDataPageToken = () => {
	const pageTokensRef = useRef<Map<number, string>>(new Map());

	const addPageToken = (page: number, token: string) => {
		pageTokensRef.current.set(page, token);
	};

	const clearPageTokens = () => {
		pageTokensRef.current.clear();
	};

	return {
		pageTokens: pageTokensRef.current,
		addPageToken,
		clearPageTokens,
	};
};
