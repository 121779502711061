import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { MAIN_POOL_ID } from '@drift/common';

/**
 * If poolId is 0: Returns the currently selected userKey
 *
 * If poolId is NOT 0: Returns the userKey for the first user matching the poolId or null, no matter which account is selected
 *
 * @param poolId | undefined
 * @returns
 */
const useCurrentOrIsolatedPoolUserKey = (
	poolId: number,
	preferIsolatedAccount?: boolean
) => {
	const accountToUse = useDriftAccountStore((s) => {
		const useMainPool = !poolId || poolId === MAIN_POOL_ID;

		if (useMainPool) {
			const currentAccount = s.accounts[s.currentUserKey];
			const isCurrentAccountIsolatedPool =
				currentAccount?.poolId !== MAIN_POOL_ID;

			if (isCurrentAccountIsolatedPool) {
				return undefined;
			} else {
				return currentAccount;
			}
		}

		const isolatedAccountList = Object.values(s.accounts).filter(
			(a) => a.poolId === poolId
		);

		if (isolatedAccountList.length === 0) {
			if (preferIsolatedAccount) {
				return undefined;
			} else {
				return s.accounts[s.currentUserKey];
			}
		}

		return isolatedAccountList[0];
	});

	return accountToUse?.userKey;
};

export default useCurrentOrIsolatedPoolUserKey;
