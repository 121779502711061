import PreLaunchMarketIcon from '../components/PreLaunchMarketAlerts/PreLaunchMarketIcon';
import React from 'react';

export const MAX_SPOT_MARKETS_PER_USER = 8;
export const MAX_SPOT_POSITIONS_ERROR_MESSAGE = {
	title: 'Max spot positions/orders reached',
	message: `You have reached the maximum number of spot positions (${MAX_SPOT_MARKETS_PER_USER}) for a single user account. This includes spot balances and open orders. To add new positions, you can: cancel existing spot orders, close spot positions, or you can create a new subaccount.`,
};

// market index of perp markets whose base token are SPL tokens
export const SPL_PERP_MARKETS = [
	0, // SOL
	4, // Bonk
	12, // RNDR
	14, // HNT
	18, // PYTH
	20, // JTO
	23, // WIF
	24, // JUP
	27, // W
	28, // KMNO
	29, // TNSR
	30, // DRIFT
	32, // IO
	33, // ZEX
	34, // POPCAT
	35, // WEN
	44, // MOTHER
	45, // MOODENG
	51, // MEW
	52, // MICHI
	53, // GOAT
	54, // FWOG
	55, // PNUT
	56, // RAY
	61, // ME
	62, // PENGU
	63, // ai16z
	64, // trump
	65, // melania
	69, // kaito
];

/**
 * These extra tags can be searched for in the market search bar.
 * Perp markets has full names in their market config, so we don't need to add them here.
 */
export const MARKET_TAGS = {
	spot: {
		1: ['solana'],
		2: ['marinade'],
		3: ['bitcoin'],
		4: ['ethereum'],
		5: ['tether'],
		8: ['blazesol'],
		9: ['jito'],
		10: ['dogwifhat'],
		11: ['jupiter'],
		12: ['render'],
		13: ['wormhole'],
		14: ['tensor'],
		16: ['sanctum'],
		17: ['drift'],
		18: ['ondo'],
		19: ['jupiter'],
		20: ['popcat'],
		21: ['sanctum'],
		22: ['paypal'],
		23: ['ethena'],
		24: ['ethena'],
		25: ['binance'],
		26: ['iggy', 'azalea'],
		30: ['magic', 'eden'],
		31: ['pudgy'],
		35: ['ai16z'],
		36: ['trump', 'usa'],
		37: ['melania', 'usa', 'trump'],
		38: ['agora'],
	},
	perp: {
		11: ['optimism'],
		12: ['render'],
		13: ['ripple'],
		14: ['helium'],
		15: ['injective'],
		16: ['chainlink'],
		22: ['avalanche'],
		23: ['dogwifhat'],
		24: ['jupiter'],
		27: ['wormhole'],
		28: ['kamino'],
		29: ['tensor'],
		30: ['drift'],
		31: ['sanctum'],
		33: ['zeta'],
		34: ['popcat'],
		42: ['telegram'],
		44: ['iggy', 'azalea'],
		61: ['magic', 'eden'],
		62: ['pudgy'],
		63: ['ai16z'],
		64: ['trump', 'usa'],
		65: ['melania', 'usa', 'trump'],
		69: ['kaito'],
		70: ['story'],
	},
};

export enum MarketCategory {
	All,
	NewAndTrending,
	PreLaunch,
	Solana,
	L1And2,
	Meme,
	L1,
	Spot,
	// PredictionMarket,
}

export const PERP_MARKET_CATEGORIES_CONFIG: {
	value: MarketCategory;
	label: React.ReactNode;
}[] = [
	{
		value: MarketCategory.All,
		label: 'All Markets',
	},
	{
		value: MarketCategory.NewAndTrending,
		label: 'New & Trending',
	},
	// {
	// 	value: MarketCategory.PredictionMarket,
	// 	label: 'Prediction Markets',
	// },
	{
		value: MarketCategory.PreLaunch,
		label: (
			<div className="flex items-center gap-1">
				<PreLaunchMarketIcon />
				<span>Pre-Launch</span>
			</div>
		),
	},
	{
		value: MarketCategory.Solana,
		label: 'Solana',
	},
	{
		value: MarketCategory.L1And2,
		label: 'L1 & 2',
	},
	{
		value: MarketCategory.Meme,
		label: 'Meme',
	},
	{
		value: MarketCategory.Spot,
		label: 'Spot',
	},
];

export const SPOT_MARKET_CATEGORIES_CONFIG: {
	value: MarketCategory;
	label: React.ReactNode;
}[] = [
	{
		value: MarketCategory.All,
		label: 'All Markets',
	},
];
