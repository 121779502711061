import Env from 'src/environmentVariables/EnvironmentVariables';
import { HistoryType } from 'src/@types/historyTables';
import { Dayjs } from 'dayjs';
import { notify } from './notifications';
import { MarketFilter } from 'src/@types/types';
import { MAX_NUM_OF_MONTHS_FETCH_LONG_TERM } from 'src/constants/historyTables';
import { dlog } from '../dev';

type DataApiResponse<T> = {
	success: boolean;
	records: T[];
	meta: {
		nextPage: null | string;
	};
};

export type ShortTermDataApiParams = {
	marketFilter?: MarketFilter;
	orderId?: number;
};

const API_SECRET = process.env.NEXT_PUBLIC_INTERNAL_SECRET ?? '';

const USER_ACCOUNT_PLACEHOLDER = '{userAccount}';
const AUTHORITY_ID_PLACEHOLDER = '{authorityId}';
const MARKET_FILTER_PLACEHOLDER = '{marketFilter}';
const ORDER_ID_PLACEHOLDER = '{orderId}';
const YEAR_PLACEHOLDER = '{year}';
const MONTH_PLACEHOLDER = '{month}';
const REPLACEABLE_PLACEHOLDERS_TO_PARAMS = {
	[USER_ACCOUNT_PLACEHOLDER]: 'userAccount',
	[AUTHORITY_ID_PLACEHOLDER]: 'authority',
	[MARKET_FILTER_PLACEHOLDER]: 'marketFilter',
	[ORDER_ID_PLACEHOLDER]: 'orderId',
	[YEAR_PLACEHOLDER]: 'year',
	[MONTH_PLACEHOLDER]: 'month',
};
const REPLACEABLE_PLACEHOLDERS = Object.keys(
	REPLACEABLE_PLACEHOLDERS_TO_PARAMS
);

const SHORT_TERM_DATA_API_LOOKUP: Partial<Record<HistoryType, string>> = {
	bal: `/user/${USER_ACCOUNT_PLACEHOLDER}/lp`,
	deposits: `/user/${USER_ACCOUNT_PLACEHOLDER}/deposits`,
	predictionTrades: `/user/${USER_ACCOUNT_PLACEHOLDER}/predictions`,
	swaps: `/user/${USER_ACCOUNT_PLACEHOLDER}/swaps`,
	'funding-payments': `/user/${USER_ACCOUNT_PLACEHOLDER}/fundingPayments`,
	'if-staking': `/authority/${AUTHORITY_ID_PLACEHOLDER}/insuranceFundStake`,
	liquidations: `/user/${USER_ACCOUNT_PLACEHOLDER}/liquidations`,
	'settled-balances': `/user/${USER_ACCOUNT_PLACEHOLDER}/settlePnl`,
	trades: `/user/${USER_ACCOUNT_PLACEHOLDER}/trades`,
	orders: `/user/${USER_ACCOUNT_PLACEHOLDER}/orders/${MARKET_FILTER_PLACEHOLDER}`,
	orderActions: `/user/${USER_ACCOUNT_PLACEHOLDER}/orders/${ORDER_ID_PLACEHOLDER}/actions`,
};
const LONG_TERM_DATA_API_LOOKUP: Partial<Record<HistoryType, string>> = {
	bal: `/user/${USER_ACCOUNT_PLACEHOLDER}/lp/${YEAR_PLACEHOLDER}/${MONTH_PLACEHOLDER}`,
	deposits: `/user/${USER_ACCOUNT_PLACEHOLDER}/deposits/${YEAR_PLACEHOLDER}/${MONTH_PLACEHOLDER}`,
	predictionTrades: `/user/${USER_ACCOUNT_PLACEHOLDER}/predictions/${YEAR_PLACEHOLDER}/${MONTH_PLACEHOLDER}`,
	swaps: `/user/${USER_ACCOUNT_PLACEHOLDER}/swaps/${YEAR_PLACEHOLDER}/${MONTH_PLACEHOLDER}`,
	'funding-payments': `/user/${USER_ACCOUNT_PLACEHOLDER}/fundingPayments/${YEAR_PLACEHOLDER}/${MONTH_PLACEHOLDER}`,
	'if-staking': `/authority/${AUTHORITY_ID_PLACEHOLDER}/insuranceFundStake/${YEAR_PLACEHOLDER}/${MONTH_PLACEHOLDER}`,
	liquidations: `/user/${USER_ACCOUNT_PLACEHOLDER}/liquidations/${YEAR_PLACEHOLDER}/${MONTH_PLACEHOLDER}`,
	'settled-balances': `/user/${USER_ACCOUNT_PLACEHOLDER}/settlePnl/${YEAR_PLACEHOLDER}/${MONTH_PLACEHOLDER}`,
	trades: `/user/${USER_ACCOUNT_PLACEHOLDER}/trades/${YEAR_PLACEHOLDER}/${MONTH_PLACEHOLDER}`,
};

const getShortTermDataApiRouteForHistoryPage = (
	historyType: HistoryType,
	params?: ShortTermDataApiParams & {
		userAccount?: string;
		authority?: string;
	}
) => {
	const route = SHORT_TERM_DATA_API_LOOKUP[historyType];
	if (!route) {
		throw new Error(`History type ${historyType} not found`);
	}

	const placeholdersToReplace = REPLACEABLE_PLACEHOLDERS.filter(
		(placeholder) => {
			return route.includes(placeholder);
		}
	);

	const routeWithPlaceholdersReplaced = placeholdersToReplace.reduce(
		(acc, placeholder) => {
			const arg =
				params[
					REPLACEABLE_PLACEHOLDERS_TO_PARAMS[
						placeholder as keyof typeof REPLACEABLE_PLACEHOLDERS_TO_PARAMS
					] as keyof typeof params
				];

			if (arg === undefined || arg === null) {
				throw new Error(`Argument for placeholder '${placeholder}' not found`);
			}

			return acc.replace(placeholder, arg.toString());
		},
		route
	);

	return routeWithPlaceholdersReplaced;
};

const getLongTermDataApiRouteForHistoryPage = (
	historyType: HistoryType,
	params?: {
		userAccount?: string;
		authority?: string;
		month?: number;
		year?: number;
	}
) => {
	const route = LONG_TERM_DATA_API_LOOKUP[historyType];

	if (!route) {
		throw new Error(`History type ${historyType} not found`);
	}

	const placeholdersToReplace = REPLACEABLE_PLACEHOLDERS.filter(
		(placeholder) => {
			return route.includes(placeholder);
		}
	);

	const routeWithPlaceholdersReplaced = placeholdersToReplace.reduce(
		(acc, placeholder) => {
			const arg =
				params[
					REPLACEABLE_PLACEHOLDERS_TO_PARAMS[
						placeholder as keyof typeof REPLACEABLE_PLACEHOLDERS_TO_PARAMS
					] as keyof typeof params
				];

			if (arg === undefined || arg === null) {
				throw new Error(`Argument for placeholder '${placeholder}' not found`);
			}

			if (placeholder === MONTH_PLACEHOLDER) {
				return acc.replace(placeholder, `${params?.month + 1}`);
			}

			return acc.replace(placeholder, arg.toString());
		},
		route
	);

	return routeWithPlaceholdersReplaced;
};

const createDataApiFullUrl = (path: string, pageToken?: string) => {
	return `${Env.dataApiUrl}${path}${pageToken ? `?page=${pageToken}` : ''}`;
};

class DataApiClient {
	private static readonly MAX_LONG_TERM_PAGINATION_DEPTH = 1; // prevent continuous fetching for accounts with a lot of data

	private static getFromDataApi<T>(
		path: string,
		pageToken?: string
	): Promise<DataApiResponse<T>> {
		const fullUrl = createDataApiFullUrl(path, pageToken);

		return new Promise<DataApiResponse<T>>((res) => {
			const headers = new Headers();

			if (API_SECRET) {
				headers.append('Authorization', API_SECRET);
			}

			fetch(`${fullUrl}`, {
				headers,
			})
				.then(async (response) => {
					if (!response.ok) {
						res({
							success: false,
							records: [],
							meta: {
								nextPage: null,
							},
						});
						return;
					}

					const body = await response.json();

					res(body);
				})
				.catch(() => {
					res({ success: false, records: [], meta: { nextPage: null } });
				});
		});
	}

	public static async fetchShortTermData<T>(
		historyType: HistoryType,
		pageToken?: string,
		params?: {
			marketFilter?: MarketFilter;
			orderId?: number;
			userAccount?: string;
			authority?: string;
		}
	) {
		const route = getShortTermDataApiRouteForHistoryPage(historyType, params);

		dlog(`history_tables_v2`, `fetching_short_term_data`, {
			route,
			pageToken,
		});

		const result = await this.getFromDataApi<T>(route, pageToken);

		return result;
	}

	/**
	 * Fetch the necessary data for each month in the given date range. e.g. if the date range is 3rd June to 8th August, we fetch data for June, July and August.
	 * Fetches up to `MAX_NUM_OF_MONTHS_TO_FETCH` months of data, with up to `MAX_LONG_TERM_PAGINATION_DEPTH` pages for each month.
	 * Note that there may be missing data in between, e.g if month 1 has 10 pages, and month 2 has 1 page, only the first `MAX_LONG_TERM_PAGINATION_DEPTH` pages of month 1 will be fetched, before combining the data from month 2
	 * There is no concept of pagination for how we handle long term data, even though there is pagination on each data API. To keep things simple and efficient, since the data fetched is up to 5000 records, we think its sufficient
	 * for most users when we limit by `MAX_LONG_TERM_PAGINATION_DEPTH` of pages per month.
	 */
	public static async fetchLongTermData<T>(
		historyType: HistoryType,
		params?: {
			userAccount?: string;
			authority?: string;
			startDate?: Dayjs;
			endDate?: Dayjs;
		}
	) {
		const monthsWithinPeriod = this.getMonthsToFetch(
			params.startDate,
			params.endDate
		).sort((a, b) => b.getTime() - a.getTime()); // descending order
		const monthsToFetch = monthsWithinPeriod.slice(
			0,
			MAX_NUM_OF_MONTHS_FETCH_LONG_TERM
		);

		const fetchPage = async (
			route: string,
			records: T[],
			currentPageToken?: string | null,
			depth = 0
		): Promise<DataApiResponse<T>> => {
			if (depth >= this.MAX_LONG_TERM_PAGINATION_DEPTH || !currentPageToken) {
				if (!currentPageToken) {
					notify({
						message:
							'There is too much data to fetch, please narrow down your date range',
						type: 'warning',
					});
				}
				return {
					success: true,
					records,
					meta: { nextPage: null },
				};
			}

			try {
				const response = await this.getFromDataApi<T>(route, currentPageToken);
				const updatedRecords = [...records, ...response.records];

				if (response.meta.nextPage) {
					return fetchPage(
						route,
						updatedRecords,
						response.meta.nextPage,
						depth + 1
					);
				}

				return {
					success: response.success,
					records: updatedRecords,
					meta: { nextPage: null },
				};
			} catch (err) {
				console.error(err);
				return {
					success: false,
					records: records,
					meta: { nextPage: currentPageToken },
				};
			}
		};

		dlog(`history_tables_v2`, `fetching_long_term_data`, {
			monthsToFetch,
			params,
		});

		const promises = monthsToFetch.map((monthDate) => {
			const month = monthDate.getMonth();
			const year = monthDate.getFullYear();

			const route = getLongTermDataApiRouteForHistoryPage(historyType, {
				userAccount: params.userAccount,
				authority: params.authority,
				month,
				year,
			});

			return fetchPage(route, [], '0');
		});

		const monthlyData = await Promise.all(promises);

		const allRecords = monthlyData.flatMap((data) => data.records);

		return {
			success: monthlyData.every((data) => data.success),
			records: allRecords,
		};
	}

	private static getMonthsToFetch(startDate: Dayjs, endDate: Dayjs) {
		const months: Date[] = [];
		let currentDate = startDate.clone().startOf('month');
		const endDateMonth = endDate.clone().startOf('month');

		while (currentDate.isSameOrBefore(endDateMonth)) {
			months.push(new Date(currentDate.year(), currentDate.month(), 1));
			currentDate = currentDate.add(1, 'month');
		}

		return months;
	}
}

export default DataApiClient;
