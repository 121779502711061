import dayjs from 'dayjs';
import { ValidationConfig } from 'src/components/DatePicker/DatePicker';

export const SHORT_TERM_API_PAGE_SIZE = 20;
export const NUM_OF_PAGES = 50;

export const DEFAULT_DATE_RANGE = {
	endDate: dayjs().startOf('day'),
	startDate: dayjs().startOf('day').subtract(31, 'days'), // based on short term API's limitation - returns up to 31 days of data
};

export const MAX_NUM_OF_MONTHS_TO_FETCH = 3;
export const MAX_NUM_OF_MONTHS_FETCH_LONG_TERM = MAX_NUM_OF_MONTHS_TO_FETCH + 1; // + 1 to include the first month

export const DEFAULT_VALIDATION_CONFIG: ValidationConfig = {
	// minDate: dayjs().startOf('day').subtract(MAX_NUM_OF_MONTHS_TO_FETCH, 'month'),
	// maxDate: dayjs().startOf('day'),
	allowFutureDates: false,
	maxDays: MAX_NUM_OF_MONTHS_TO_FETCH * 30,
};
