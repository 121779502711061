'use client';

import {
	BigNum,
	BSOL_STATS_API_RESPONSE,
	fetchBSolMetrics,
	MarketType,
} from '@drift-labs/sdk';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { derr, dlog } from '../dev';
import useIsMainnet from './useIsMainnet';
import Env, {
	BNSOL_MARKET_INDEX,
	CurrentSpotMarkets,
	PYUSD_BANK_INDEX,
	PYUSD_SPOT_MARKET,
	SPOT_MARKETS_LOOKUP,
	SUSDE_SPOT_MARKET,
	USDE_MARKET_INDEX,
	USDS_MARKET_INDEX,
	USDS_SPOT_MARKET,
} from 'src/environmentVariables/EnvironmentVariables';
import useBorrowLendDataForMarket from './useBorrowLendDataForMarket';
import { MarketId } from '@drift/common';
import UI_UTILS from 'src/utils/uiUtils';
import { getMarketIconSrc } from 'src/components/Utils/MarketIcon';

export type AprDetails = {
	source: string;
	apr: number;
	hideApr?: boolean;
	icon?: string;
	helperText?: string | React.ReactNode;
};

type SANCTUM_API_RESPONSE = {
	apys: { [symbol: string]: number };
	errs: any; // just ignore these for now
};

type AprBreakdown = Record<number, AprDetails[]>;

const ALREADY_FETCHED_REF = { current: false };

// todo - should this be its own section in the market selector?
// exclude bSOL here since it has its own off chain logic
const LSTS = ['mSOL', 'jitoSOL', 'INF', 'dSOL'];
const LST_INDICES = SPOT_MARKETS_LOOKUP.filter((mkt) =>
	LSTS.includes(mkt.symbol)
).map((mkt) => mkt.marketIndex);

const USDE_METRIC = [
	{
		source: 'Ethena Points',
		apr: 0,
		hideApr: true,
		icon: getMarketIconSrc({ baseSymbol: 'usde' }),
		helperText: (
			<div className="mt-1">
				Earn 20 Sats a day per USDe.
				<br />
				<br />
				<a
					href="https://www.drift.trade/updates/drift-partners-with-ethena-to-launch-usde-and-susde-on-solana"
					target="_blank"
					rel="noopener noreferrer"
				>
					Learn More
				</a>
			</div>
		),
	},
];

const MAX_RETRIES = 3;
const INITIAL_DELAY_MS = 2000;

async function fetchWithRetry<T>(
	fetchFn: () => Promise<T>,
	retries = MAX_RETRIES,
	delay = INITIAL_DELAY_MS
): Promise<T> {
	try {
		return await fetchFn();
	} catch (error) {
		if (retries === 0) {
			throw error;
		}

		dlog(
			'apr_metrics_fetch_retry',
			`Retrying... Attempts remaining: ${retries}`
		);
		await new Promise((resolve) => setTimeout(resolve, delay));

		return fetchWithRetry(fetchFn, retries - 1, delay * 2);
	}
}

function useAprBreakdowns(): AprBreakdown {
	const [bsolMetrics, setBSolMetrics] = useState<AprDetails[]>([]);
	const [otherLstMetrics, setOtherLstMetrics] = useState<AprBreakdown>({});
	const [pyusdMetrics, setPyusdMetrics] = useState<AprDetails[]>([]);
	const [susdeMetrics, setSusdeMetrics] = useState<AprDetails[]>([]);
	const [bnsolMetrics, setBNSOLMetrics] = useState<AprDetails[]>([]);
	const [usdsMetrics, setUsdsMetrics] = useState<AprDetails[]>([]);
	const isMainnet = useIsMainnet();

	const pyusdBorrowLendData = useBorrowLendDataForMarket(
		new MarketId(PYUSD_SPOT_MARKET?.marketIndex, MarketType.SPOT)
	);

	const usdsBorrowLendData = useBorrowLendDataForMarket(
		new MarketId(USDS_SPOT_MARKET?.marketIndex, MarketType.SPOT)
	);

	useEffect(() => {
		if (!isMainnet) return;

		if (ALREADY_FETCHED_REF.current) {
			// Should only fetch apr metrics once per session otherwise we can get rate limited/blocked.
			derr(`apr_metrics_already_fetched`);
		}

		fetchAndSetOtherLstMetrics();
		fetchAndSetBSolMetrics();
		fetchAndSetSusdeMetrics();
		fetchAndSetBNSOLMetrics();

		ALREADY_FETCHED_REF.current = true;
	}, [isMainnet]);

	useEffect(() => {
		if (pyusdBorrowLendData && Env.pyusdBonusPerWeek) {
			computePyusdMetrics();
		}
	}, [pyusdBorrowLendData]);

	useEffect(() => {
		if (usdsBorrowLendData && Env.usdsBonusPerWeek) {
			computeUsdsMetrics();
		}
	}, [usdsBorrowLendData]);

	function computePyusdMetrics() {
		const pyusdAnnualBonus = BigNum.fromPrint(
			`${Env.pyusdBonusPerWeek * 52}`,
			pyusdBorrowLendData.bankConfig.precisionExp
		);

		const pyusdApr = pyusdBorrowLendData.totalDepositsBase?.gtZero()
			? (pyusdAnnualBonus.toNum() /
					pyusdBorrowLendData.totalDepositsBase.toNum()) *
			  100
			: 0;

		setPyusdMetrics([
			{
				source: 'PYUSD Rewards',
				apr: pyusdApr,
				icon: getMarketIconSrc({ baseSymbol: 'pyusd' }),
				helperText: `(${UI_UTILS.toFixedLocaleString(
					Env.pyusdBonusPerWeek / 7,
					2
				)} PYUSD distributed daily across all PYUSD deposits)`,
			},
		]);
	}

	function computeUsdsMetrics() {
		const usdsAnnualBonus = BigNum.fromPrint(
			`${Env.usdsBonusPerWeek * 52}`,
			usdsBorrowLendData.bankConfig.precisionExp
		);

		const usdsApr = usdsBorrowLendData.totalDepositsBase?.gtZero()
			? (usdsAnnualBonus.toNum() /
					usdsBorrowLendData.totalDepositsBase.toNum()) *
			  100
			: 0;

		setUsdsMetrics([
			{
				source: 'USDS Rewards',
				apr: usdsApr,
				icon: getMarketIconSrc({ baseSymbol: 'usds' }),
				helperText: `(${UI_UTILS.toFixedLocaleString(
					Env.usdsBonusPerWeek / 7,
					2
				)} USDS distributed daily across all USDS deposits)`,
			},
		]);
	}

	async function fetchAndSetBSolMetrics() {
		try {
			const statsResponse = await fetchWithRetry(async () => {
				const response = await fetchBSolMetrics();
				if (!response.ok) {
					throw new Error(`Failed to fetch bSOL metrics: ${response.status}`);
				}
				return response;
			});

			const data = (await statsResponse.json()) as BSOL_STATS_API_RESPONSE;
			const baseApy = data?.stats?.apy.base;
			const lendingMultiplier = data?.stats?.apy.lending;
			const blzeApy = data?.stats?.apy.blze * lendingMultiplier;

			setBSolMetrics([
				{
					source: 'bSOL APY',
					apr: baseApy,
				},
				{
					source: 'BLZE Rewards',
					apr: blzeApy,
					icon: '/assets/icons/blze.svg',
				},
			]);
		} catch (error) {
			derr('Failed to fetch bSOL metrics after retries:', error);
			setBSolMetrics([]);
		}
	}

	async function fetchAndSetSusdeMetrics() {
		try {
			const requestUrl = `https://ethena.fi/api/yields/protocol-and-staking-yield`;

			const data = await fetchWithRetry(async () => {
				const response = await fetch(requestUrl);
				if (!response.ok) {
					throw new Error(`Failed to fetch sUSDe metrics: ${response.status}`);
				}
				return response.json();
			});

			const susdeYield = data?.stakingYield?.value ?? 0;

			setSusdeMetrics([
				{
					source: 'sUSDe Yield',
					apr: susdeYield,
					icon: getMarketIconSrc({ baseSymbol: 'susde' }),
					helperText: (
						<div className="mt-1">
							Earn 5 Sats a day per sUSDe.
							<br />
							<br />
							<a
								href="https://www.drift.trade/updates/drift-partners-with-ethena-to-launch-usde-and-susde-on-solana"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn More
							</a>
						</div>
					),
				},
			]);
		} catch (error) {
			derr('Failed to fetch sUSDe metrics after retries:', error);
			setSusdeMetrics([]);
		}
	}

	async function fetchAndSetOtherLstMetrics() {
		try {
			dlog(`apr_metrics`, `fetching_sanctum_lst_metrics`);

			const requestUrl =
				`https://extra-api.sanctum.so/v1/apy/latest?${LST_INDICES.map(
					(val, index) => {
						return `${index !== 0 ? '&lst=' : 'lst='}${
							CurrentSpotMarkets.find((mkt) => mkt.marketIndex === val).symbol
						}`;
					}
				)}`.replaceAll(',', '');

			const data = (await fetchWithRetry(async () => {
				const response = await fetch(requestUrl);
				if (!response.ok) {
					throw new Error(`Failed to fetch LST metrics: ${response.status}`);
				}
				return response.json();
			})) as SANCTUM_API_RESPONSE;

			const apyObj: Record<number, AprDetails[]> = {};

			Object.keys(data.apys).forEach((key) => {
				apyObj[
					CurrentSpotMarkets.find((mkt) => mkt.symbol === key).marketIndex
				] = [{ source: `${key} APY`, apr: data.apys[key] * 100 }];
			});

			setOtherLstMetrics(apyObj);
		} catch (error) {
			derr('Failed to fetch LST metrics after retries:', error);
			setOtherLstMetrics({});
		}
	}

	async function fetchAndSetBNSOLMetrics() {
		try {
			const requestUrl = `https://www.binance.com/bapi/earn/v1/friendly/earn/restaking/project/detail`;

			const data = await fetchWithRetry(async () => {
				const response = await fetch(requestUrl);
				if (!response.ok) {
					throw new Error(`Failed to fetch BNSOL metrics: ${response.status}`);
				}
				return response.json();
			});

			const apy = +data.data.apy;

			setBNSOLMetrics([
				{
					source: 'BNSOL APY',
					apr: isNaN(apy) ? 0 : apy * 100,
				},
			]);
		} catch (error) {
			derr('Failed to fetch BNSOL metrics after retries:', error);
			setBNSOLMetrics([]);
		}
	}

	if (!isMainnet) {
		return {
			[PYUSD_BANK_INDEX]: pyusdMetrics,
		};
	}

	return {
		8: bsolMetrics,
		[PYUSD_BANK_INDEX]: pyusdMetrics,
		[USDS_MARKET_INDEX]: usdsMetrics,
		[SUSDE_SPOT_MARKET.marketIndex]: susdeMetrics,
		[USDE_MARKET_INDEX]: USDE_METRIC,
		[BNSOL_MARKET_INDEX]: bnsolMetrics,
		...otherLstMetrics,
	};
}

export default singletonHook({}, useAprBreakdowns);
